import './GalleryItem.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';

import fmotdImg from '../../../assets/img/logo-fmotd.png';
import errorIcon from '../../../assets/img/svg/error.svg';
import menuIcon from '../../../assets/img/svg/menu-dots.svg';
import noPhotoIcon from '../../../assets/img/svg/no-picture.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { spinnerRequest } from '../../../shared/spinnerRequest';
import Button from '../../Button/Button';

function GalleryItem({
  item,
  statusClassName,
  openGallery,
  spinner,
  itemDeleted,
  menuVisible,
  setMenuVisible,
  itemUpdated,
  language,
  fmotdOverlay,
}) {
  const menuRef = useRef();
  const menuIconRef = useRef();

  const history = useHistory();
  const intl = useIntl();
  const isVideoGallery = history.location.pathname.includes('videos');

  const [, deleteGalleryRequest] = useAxios(
    {
      url: `/galleries/${item.id}`,
      method: 'DELETE',
    },
    { manual: true }
  );

  const [, archiveGalleryRequest] = useAxios(
    {
      url: `/galleries/${item.id}/archive`,
      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  const [, publishGalleryRequest] = useAxios(
    {
      url: `/galleries/${item.id}/publish`,
      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  function deleteGallery() {
    spinnerRequest({
      request: deleteGalleryRequest,
      spinner,
    })
      .then(() => itemDeleted(item.id))
      .catch(() => {});
  }

  function archiveGallery() {
    spinnerRequest({
      request: archiveGalleryRequest,
      spinner,
    })
      .then(() => {
        itemUpdated();
      })
      .catch(() => {});
  }

  function publishGallery() {
    const payload = {
      publish: dayjs().format('YYYY-MM-DD'),
    };
    spinnerRequest({
      request: publishGalleryRequest,
      spinner,
      payload: {
        data: payload,
      },
    })
      .then(() => {
        itemUpdated();
      })
      .catch(() => {});
  }
  const galleryMenuClass = classNames('toggle-menu gallery-menu', {
    'no-delete': item.total_number,
  });

  const galleryMenuButtonClass = classNames('btn-menu', {
    open: menuVisible,
  });

  useOutsideClick(
    menuRef,
    () => {
      if (menuVisible) {
        setMenuVisible(false);
      }
    },
    menuIconRef
  );

  return (
    <div className="gallery-item thumbnail-item">
      <div className="thumbnail-wrapper">
        <div
          className="thumbnail"
          onClick={(event) => {
            if (
              event.target.id !== 'menu-dots' &&
              !event.target.id.includes('gallery-menu-id') &&
              event.target.tagName !== 'path' &&
              !event.target.closest('menu-dots')
            ) {
              openGallery(item.type, item.id, 'settings');
            }
          }}
        >
          {item.thumbnail ? (
            <div
              className="thumbnail-image"
              style={{
                backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${item.thumbnail}')`,
              }}
            >
              {item?.fmd ? (
                <img
                  className="fmotd-logo"
                  src={fmotdImg}
                  alt="Free Movie of the Day"
                />
              ) : null}
            </div>
          ) : (
            <ReactSVG src={noPhotoIcon} className={'no-photo'} wrapper="span" />
          )}
          <div className="thumbnail-options">
            <Button
              intlTranslate={false}
              icon={menuIcon}
              variant="icon-only"
              className={galleryMenuButtonClass}
              innerRef={menuIconRef}
              onClick={() => {
                if (!menuVisible) {
                  setMenuVisible(true);
                }
              }}
              id={`gallery-menu-id-${item.id}`}
            />
          </div>
          {menuVisible && (
            <div
              className={galleryMenuClass}
              id={`btn-menu-${item.id}`}
              ref={menuRef}
            >
              <div
                className="menu-item"
                onClick={(event) => {
                  openGallery(item.type, item.id, 'settings');
                  event.stopPropagation();
                }}
              >
                <FormattedMessage id="GALLERY_MENU_SETTINGS" />
              </div>
              {!isVideoGallery && (
                <div
                  className="menu-item"
                  onClick={(event) => {
                    openGallery(item.type, item.id, 'add-images');
                    event.stopPropagation();
                  }}
                >
                  <FormattedMessage id="PHOTO_GALLERY_MENU_ADD_IMAGES" />
                </div>
              )}
              {isVideoGallery && !item.total_number && (
                <div
                  className="menu-item"
                  onClick={(event) => {
                    openGallery(item.type, item.id, 'add-images');
                    event.stopPropagation();
                  }}
                >
                  <FormattedMessage id="VIDEO_GALLERY_MENU_ADD_VIDEO" />
                </div>
              )}
              <div
                className="menu-item"
                onClick={(event) => {
                  openGallery(item.type, item.id, 'preview');
                  event.stopPropagation();
                }}
              >
                <FormattedMessage id="GALLERY_MENU_UPLOAD_PREVIEW" />
              </div>
              <div
                className="menu-item"
                onClick={(event) => {
                  openGallery(item.type, item.id, 'edit-texts');
                  event.stopPropagation();
                }}
              >
                <FormattedMessage id="GALLERY_MENU_EDIT_TEXTS" />
              </div>
              <div
                className="menu-item"
                onClick={(event) => {
                  openGallery(item.type, item.id, 'documents');
                  event.stopPropagation();
                }}
              >
                <FormattedMessage id="GALLERY_ACTORS_DOCUMENTS" />
              </div>
              <div
                className="menu-item"
                onClick={(event) => {
                  openGallery(item.type, item.id, 'comments');
                  event.stopPropagation();
                }}
              >
                <FormattedMessage id="COMMENTS" /> ({item.comments || 0})
              </div>
              {!item.total_number && item.state === 'unpublished' && (
                <div
                  className="menu-item"
                  onClick={(event) => {
                    deleteGallery();
                    setMenuVisible(false);
                    event.stopPropagation();
                  }}
                >
                  <FormattedMessage id="GALLERY_MENU_DELETE" />
                </div>
              )}
              {item.state === 'published' && !item.fmd && (
                <div
                  className="menu-item"
                  onClick={(event) => {
                    archiveGallery();
                    setMenuVisible(false);
                    event.stopPropagation();
                  }}
                >
                  <FormattedMessage id="GALLERY_MENU_ARCHIVE" />
                </div>
              )}
              {item.state === 'archived' && (
                <div
                  className="menu-item"
                  onClick={(event) => {
                    publishGallery();
                    setMenuVisible(false);
                    event.stopPropagation();
                  }}
                >
                  <FormattedMessage id="PUBLISH_NOW" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="textbox">
        <div
          className="gallery-title-wrapper"
          onClick={() => openGallery(item.type, item.id, 'settings')}
        >
          {item.title?.content ? (
            <span className="gallery-title">{item.title?.content}</span>
          ) : (
            <span className="unnamed-gallery">
              {intl.formatMessage({ id: 'UNNAMED_GALLERY' })}
            </span>
          )}
        </div>
        <div className="textbox-stats-div">
          <div>
            <span className="number">{item.price} Coins</span>{' '}
            {isVideoGallery
              ? intl.formatMessage({ id: 'VIDEO_COINS_PER_VIDEO' })
              : intl.formatMessage({ id: 'PHOTO_COINS_PER_PHOTO' })}
          </div>
          {item.schedule && item.state !== 'published' ? (
            <div className="publish-label-div">
              {intl.formatMessage({ id: 'PUBLISH_DATE' })}
            </div>
          ) : (
            <>
              {!isVideoGallery && (
                <div>
                  <span>{item.total_number}</span>{' '}
                  {intl.formatMessage({ id: 'PHOTOS' })}
                </div>
              )}
            </>
          )}
        </div>
        <div className="textbox-stats-div">
          <div className="comments">
            <span className="number">{item.comments || 0}</span>{' '}
            <FormattedMessage id="COMMENTS" />
          </div>
          {item.schedule && item.state !== 'published' ? (
            <div>
              {dayjs(item.schedule).format(
                language === 'de' ? 'D. MMM YYYY' : 'MMM D, YYYY'
              )}
            </div>
          ) : (
            <div>
              {
                <span className="number">
                  {item.sales_number !== 0 && item.sales_number}
                </span>
              }{' '}
              {item.sales_number
                ? intl.formatMessage({ id: 'TIMES_SOLD' })
                : intl.formatMessage({ id: 'NOT_SOLD_YET' })}
            </div>
          )}
        </div>
      </div>
      <div className={statusClassName}>
        {item.schedule && item.state !== 'published'
          ? intl.formatMessage({ id: 'scheduled' })
          : intl.formatMessage({ id: item.state })}
      </div>
      {(!!item.reason_for_reject ||
        (item.thumbnail_denied && !item.thumbnail)) && (
        <div className="badge red rejection-badge">
          <ReactSVG src={errorIcon} wrapper="span" className="icon" />
        </div>
      )}
    </div>
  );
}

export default GalleryItem;

