import './ProfilePicture.scss';

import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import posingImage1 from '../../../../../assets/img/profile-pictures/posing1.jpg';
import posingImage2 from '../../../../../assets/img/profile-pictures/posing2.jpg';
import posingImage3 from '../../../../../assets/img/profile-pictures/posing3.jpg';
import posingImage4 from '../../../../../assets/img/profile-pictures/posing4.jpg';
import posingImage5 from '../../../../../assets/img/profile-pictures/posing5.jpg';
import posingImage6 from '../../../../../assets/img/profile-pictures/posing6.jpg';
import { uuid } from '../../../../../utils';
import FieldErrorWrapper from '../../../../Forms/FieldErrorWrapper/FieldErrorWrapper';
import StepperContext from '../../../../Stepper/StepperContext';
import Uploader from '../../../../Uploader';
import ImageItem from '../../../../Uploader/ImageItem/ImageItem';
import { hasReviewError } from '../../Onboarding.functions';

function ProfilePicture({
  update = () => {},
  updateImages = () => {},
  isMobile,
  user = {},
  openOverlay = () => {},
  uploadError = null,
  uploadResult = null,
  uploadIndicator = '',
  uploadFile,
}) {
  const indicator = 'profilepicture';
  const uploadFormData = useMemo(() => new FormData(), []);
  uploadFormData.append('fsk[]', 'fsk18');

  const intl = useIntl();
  const fsk18Error = intl.formatMessage({ id: 'IMAGE_UPLOAD_ERROR' });
  const [imageError, setImageError] = useState({ fsk18: '' });
  const [profilePictureOnboarding, setProfilePictureOnboarding] = useState([]);

  const { values, touched, setFieldValue } = useFormikContext();

  const {
    currentItemIndex,
    nextItemIndex,
    setCurrentItemIndex,
    sendingToReview,
    setSendingToReview,
    markCurrentItemAsValid,
    markCurrentItemAsInvalid,
  } = useContext(StepperContext);

  useEffect(() => {
    if (
      (!nextItemIndex && nextItemIndex !== 0) ||
      currentItemIndex === nextItemIndex
    ) {
      return;
    }

    setCurrentItemIndex(nextItemIndex);
  }, [currentItemIndex, nextItemIndex, setCurrentItemIndex]);

  useEffect(() => {
    setProfilePictureOnboarding(values?.profile_pictures?.in_progress);
  }, [values?.profile_pictures?.in_progress]);

  useEffect(() => {
    if (sendingToReview !== 'pending') {
      return;
    }
    setSendingToReview('ready');
  }, [sendingToReview, setSendingToReview]);

  const openCam = () => {
    const stamp = uuid();

    openOverlay({
      stamp,
      Component: 'Cam',
      props: {
        camstamp: stamp,
        indicator,
        uploadPath: '/user/images',
        uploadFormData: uploadFormData,
        title: intl.formatMessage({ id: 'SNAPSHOT_PROFILE_PICTURE_TITLE' }),
        minScreenshotWidth: 1600,
        filename: `snapshot${dayjs().format('DDMMYYYYHHmmss')}.jpg`,
        cropping: true,
      },
    });
  };
  useEffect(() => {
    if (uploadIndicator !== indicator) {
      return;
    }
    if (uploadError) {
      if (!values.images.length) {
        markCurrentItemAsInvalid();
      }
      setImageError((prevError) => {
        return {
          ...prevError,
          fsk18: fsk18Error,
        };
      });
      return uploadFile('', false, indicator);
    }
    if (uploadResult && uploadResult?.data) {
      setProfilePictureOnboarding(uploadResult?.data);
      updateImages(uploadResult.data);
      setFieldValue('images', uploadResult.data);
      setImageError({ ...imageError, fsk18: '' });
      if (user.account.state === 'review_needed') {
        const updatedReviewState = _.union(
          user.account.review_state_changed_fields,
          ['images']
        );
        update({
          account: {
            ...user.account,
            review_state_changed_fields: updatedReviewState,
          },
        });
      }
      markCurrentItemAsValid();
      return uploadFile('', false, indicator);
    }
  }, [
    uploadResult,
    uploadError,
    uploadIndicator,
    indicator,
    setImageError,
    markCurrentItemAsInvalid,
    values,
    fsk18Error,
    updateImages,
    setFieldValue,
    markCurrentItemAsValid,
    imageError,
    user,
    update,
    uploadFile,
  ]);

  return (
    <div className="content-inner profile-picture-step">
      <h1 className="step-headline">
        <FormattedMessage id="UPLOAD_PROFILE_PICTURE" />
      </h1>

      <div className="content-grid">
        <div className="grid-col">
          <p className="bold">
            <FormattedMessage id="PROFILE_PICTURE_CONTENT_1" />
          </p>
          <p>
            <FormattedMessage id="PROFILE_PICTURE_CONTENT_2">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <FieldErrorWrapper name="fsk18" noGrid={true}>
            <Uploader
              indicator={indicator}
              uploadPath="/user/images"
              uploadFormData={uploadFormData}
              cropping={true}
              minDimensions={{ width: 1080, height: 1080 }}
              label="ONBOARDING_ITEM_PROFILE_PICTURE"
              acceptedFileTypes={['.jpg', '.jpeg', '.jpe', '.png']}
              handleError={(error) => {
                if (error && !values.images.length) {
                  markCurrentItemAsInvalid();
                }
                setImageError({ ...imageError, fsk18: error });
              }}
              imageError={imageError.fsk18}
              hasChildrenWrapper={true}
              dropzoneProps={{ multiple: false }}
              openCam={openCam}
              isMobile={isMobile}
              className="profile-picture"
            >
              {profilePictureOnboarding &&
                profilePictureOnboarding.map((f, idx) => {
                  if (
                    (profilePictureOnboarding.length === 1 &&
                      f.fsk === 'fsk18') ||
                    (profilePictureOnboarding.length > 1 &&
                      f.fsk === 'fsk18' &&
                      f.state === 'pending')
                  ) {
                    return (
                      <ImageItem
                        key={idx}
                        src={
                          process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + f.file
                        }
                        labelTitle={
                          hasReviewError(user, 'images', touched.images)
                            ? intl.formatMessage({
                                id: 'LABEL_REJECTED',
                              })
                            : null
                        }
                        labelContent={f.comment}
                        status={
                          hasReviewError(user, 'images', touched.images)
                            ? 'disabled'
                            : null
                        }
                      />
                    );
                  }
                  return null;
                })}
            </Uploader>
          </FieldErrorWrapper>
          <p>
            <FormattedMessage id="PROFILE_PICTURES_UPLOAD_TEXT_1" />
          </p>
          <p>
            <FormattedMessage id="PROFILE_PICTURE_CONTENT_3">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <p className="no-margin">
            <FormattedMessage id="PROFILE_PICTURE_CONTENT_4">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
        <div className="grid-col">
          <div className="picture-posing">
            <p className="bold">
              <FormattedMessage id="PROFILE_PICTURE_POSING_HEADLINE" />
            </p>
            <p>
              <FormattedMessage id="PROFILE_PICTURE_POSING" />
            </p>
            <div className="posing">
              <img src={posingImage1} alt="Posing" />
              <img src={posingImage2} alt="Posing" />
              <img src={posingImage3} alt="Posing" />
            </div>
          </div>
          <div className="picture-posing">
            <p className="bold">
              <FormattedMessage id="PROFILE_PICTURE_POSING_NOFACE_HEADLINE" />
            </p>
            <p>
              <FormattedMessage id="PROFILE_PICTURE_NOFACE_POSING" />
            </p>
            <div className="posing">
              <img src={posingImage4} alt="Posing" />
              <img src={posingImage5} alt="Posing" />
              <img src={posingImage6} alt="Posing" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePicture;
