import { useEffect, useRef } from 'react';

const useOutsideClick = (ref, callback, excludeRef) => {
  const isClickedInsideRef = useRef(false);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (
        excludeRef &&
        excludeRef?.current &&
        excludeRef.current?.contains(event.target)
      ) {
        isClickedInsideRef.current = true;
      } else {
        isClickedInsideRef.current = false;
      }
    };

    const handleMouseUp = (event) => {
      if (
        ref?.current &&
        !ref.current?.contains(event.target) &&
        (!excludeRef || !excludeRef?.current?.contains(event.target)) &&
        !isClickedInsideRef?.current
      ) {
        callback();
      }
      isClickedInsideRef.current = false;
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [ref, callback, excludeRef]);
};

export default useOutsideClick;

