import { STREAMING_STOPPED } from '../../../../constants';
import { sender as senderService } from '../../../../services';
import { upgradetimer, user as userUtils } from '../../../../utils';
import {
  audio,
  cam2cam,
  camLogoutTimer,
  camUser as camUserActions,
  management as managementActions,
  revertOrigin,
  sendSystemChatMessage,
  stopUpgradetimer,
  stream,
} from '../../../';
import {
  MANAGEMENT_EVENTS_CONNECTION_DISCONNECT,
  MANAGEMENT_EVENTS_CONNECTION_TRANSPORT_CLOSE,
} from '../../../actions';

export default (management) => (reason) => {
  return (dispatch, getState) => {
    console.log(`socket.io disconnect: ${reason}`);
    if (reason === 'transport close') {
      dispatch({
        type: MANAGEMENT_EVENTS_CONNECTION_TRANSPORT_CLOSE,
      });
    }
    const {
      sender: { logins, isAuthenticated },
      streaming: { silentReconnect, transportCloseReconnectDelay },
      sender: {
        software: { origin: softwareOrigin },
        server: { serviceURL },
      },
      camUser: { camUsers },
      dialog: { dialogs },
    } = getState();
    const allDialogs = [...dialogs];
    const logger = senderService.logger({ serviceURL, softwareOrigin });
    if (isAuthenticated) {
      logger.info(`io disconnect: ${reason}`, {}, management.lastSocketId());
    }

    const activeUser = userUtils.activeUser([...camUsers, ...allDialogs]);

    if (activeUser && activeUser.hasCam2Cam && activeUser.cam2camActive) {
      dispatch(cam2cam.stop(activeUser.id));
    }
    upgradetimer
      .getAllTimerUserIds(getState().upgradetimer)
      .forEach((userId) => dispatch(stopUpgradetimer({ userId })));

    if (getState().streaming.step !== STREAMING_STOPPED) {
      console.log('stop connection because of disconnect');
      dispatch(stream.stop());
    }
    dispatch(managementActions.streaminfoTimer.stop());
    !silentReconnect && dispatch(audio.lostConnection());
    dispatch(revertOrigin(false, false));
    const user = camUsers.find((user) => user.active) || {};
    dispatch({
      type: MANAGEMENT_EVENTS_CONNECTION_DISCONNECT,
      meta: { nextActiveUser: { userId: 'system' } },
    });

    if (userUtils.isVisibleLivecamUser(user)) {
      const hasDialog = allDialogs.find(
        (d) => d.userId === `${user.clientProductId}@${user.clientCustomerId}`
      );
      if (hasDialog) {
        dispatch(camUserActions.select(userUtils.getPartner(user)));
      } else {
        dispatch(camUserActions.selectnext());
      }
    }

    dispatch(camLogoutTimer.stop());

    if (silentReconnect) {
      return dispatch(
        managementActions.connect.start(transportCloseReconnectDelay)
      );
    }
    if (logins.length > 0) {
      dispatch(sendSystemChatMessage({ id: 'systemMessage.loggedOut' }));
    }
  };
};
