import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import movieIcon from '../../../../../assets/img/svg/movie.svg';
import payttachmentIcon from '../../../../../assets/img/svg/payttachment.svg';
import api from '../../../../../utils/api';
import MessageLoading from '../MessageLoading';
import MessageResend from '../MessageResend';

function Payttachment({ message, safeMode, partner }) {
  const id = message?.payload?.payttachment?.id;
  const [file, setFile] = useState(message?.payload?.payttachment?.file);
  const [thumbnail, setThumbnail] = useState(
    message?.payload?.payttachment?.thumbnail
  );
  const [price, setPrice] = useState(message?.payload?.payttachment?.price);
  const [bought, setBought] = useState(message?.payload?.payttachment?.bought);
  const [loading, setLoading] = useState(!file);
  useEffect(() => {
    let mounted = true;
    (async () => {
      if (partner && id && !file) {
        try {
          const response = await api.get(
            `user/payttachments/${id}/check/${partner}`
          );
          if (mounted) {
            setFile(response?.data?.file);
            setThumbnail(response?.data?.thumbnail);
            setPrice(response?.data?.price);
            setBought(response?.data?.bought);
          }
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      }
    })();
    return () => {
      mounted = false;
    };
  }, [partner, id, file]);

  const intl = useIntl();
  return (
    <span className="message special payttachment">
      <span className="intro small">
        <span
          dangerouslySetInnerHTML={{
            __html: intl
              .formatMessage({
                id: loading ? 'chat.payttachment.loading' : 'chat.payttachment',
              })
              .replace('{price}', price),
          }}
        />
      </span>
      <span className="payttachment-content-wrapper">
        <MessageLoading loading={loading} />
        {safeMode || loading ? null : message.payload?.payttachment?.type ===
          'movie' ? (
          <div
            className="payttachment-thumbnail movie"
            onClick={() =>
              window.open(process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + file)
            }
          >
            <ReactSVG src={movieIcon} className="icon" wrapper="span" />
          </div>
        ) : (
          <div
            className="payttachment-thumbnail"
            style={{
              backgroundImage: `url('${
                process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + thumbnail
              }')`,
            }}
            onClick={() =>
              window.open(process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + file)
            }
          />
        )}
        <span className="payttachment-content">
          {!loading && (
            <a
              className="small"
              href={process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + file}
              rel="noreferrer"
              target="_blank"
            >
              <ReactSVG
                src={payttachmentIcon}
                className="icon"
                wrapper="span"
              />
              <span className="filename">
                {message.payload?.payttachment?.name}
              </span>
            </a>
          )}

          {loading ? null : bought ? (
            <span className="message green">
              {intl.formatMessage({ id: 'FILE_SOLD' })}
            </span>
          ) : (
            <span className="message">
              {intl.formatMessage({ id: 'FILE_NOT_SOLD' })}
            </span>
          )}
        </span>
      </span>
      <MessageResend intl={intl} message={message} />
    </span>
  );
}

export default Payttachment;
