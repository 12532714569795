import { user as userUtils } from '../../utils';
import {
  checkFriend,
  dialogRead,
  fetchAllMessages,
  getGameStats,
  getUserProfile,
  knownSenderDialog,
  usernote,
} from '..';
export default (user, isSameUser = false) =>
  async (dispatch, getState) => {
    if (!getState().user.isLoggedIn) {
      return;
    }
    let partner = user.partner;
    if (userUtils.isDialogUser(user?.type) && userUtils.hasPartner(user)) {
      partner = userUtils.getPartner(user);
    }

    if (partner === 'system') return;

    if (
      (getState().chat.selectedUser === partner ||
        (getState().chat.selectedUser === user.userId && partner)) &&
      !isSameUser
    ) {
      dispatch(fetchAllMessages(partner, true));
    }

    if (getState().user.account?.scopes?.includes('send_message')) {
      if (user.read === false) {
        dialogRead(user.id);
      }
      if (user?.flags?.includes('sender_new')) {
        knownSenderDialog(user.id);
      }
    }
    if (!userUtils.isAdmin(user)) {
      const userId = userUtils.getUserId(user);

      dispatch(checkFriend(userId));
      dispatch(usernote.get(user));
      dispatch(getUserProfile(user));
      dispatch(getGameStats(partner));
    }
  };
