import './UserList.scss';

import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { SPINNER_DEFAULT_DELAY } from '../../../../constants';
import { isLivecamCreator } from '../../../../utils';
import axiosInstance from '../../../../utils/api';
import SearchInput from '../../../Forms/SearchInput/SearchInput';
import Scrollbar from '../../../Scrollbar';
import SettingsButton from '../../../SettingsButton';
import Spinner from '../../../Spinner/Spinner';
import { activitiesFilter } from '../Livecam.functions';
import {
  gameFilter,
  messageFilters as messageFiltersWithoutGame,
} from '../SubMenu/SubMenu.functions';
import User from './User';
import UserListMenu from './UserListMenuContainer';
import Voyeur from './Voyeur';

function UserList({
  voyeurCount,
  livecamCount,
  usercolor,
  onUserClick,
  hasVoyeur,
  isEvent,
  askKickUserConfirmed,
  askKickUserConfirmedAlert,
  askIgnoreUserConfirmed,
  askIgnoreUserConfirmedAlert,
  askIgnoreUserId,
  askIgnoreUserKick,
  onUserKickConfirm,
  onUserIgnoreConfirm,
  toggleUserMenu,
  camUserFiltered,
  dialogUserFiltered,
  camUserFilter,
  filterUserlist,
  toggleUserlistMenu,
  userListMenuOpen,
  hasMic,
  isUserlistOpen,
  browser,
  askSendFriendRequestConfirmed,
  askSendFriendRequestConfirmedAlert,
  askSendFriendRequestId,
  friendRequestSent,
  user,
  askAnswerFriendRequestConfirmed,
  askAnswerFriendRequestConfirmedAlert,
  askAnswerFriendRequestId,
  askAnswerFriendRequestType,
  askAnswerFriendRequestNickname,
  answerFriendRequest,
  friendsError,
  askDeleteFriendConfirmed,
  askDeleteFriendConfirmedAlert,
  askDeleteFriendId,
  deleteFriend,
  askRemoveDeniedFriendRequestConfirmed,
  askRemoveDeniedFriendRequestConfirmedAlert,
  askRemoveDeniedFriendRequestId,
  askRemoveDeniedFriendRequestNickname,
  removeDeniedFriendRequest,
  loading,
  messagesFilter,
  gamesEnabled,
  isMobile,
  toggleUserlistView,
  isMediumLarge,
  isMedium,
  isExclusive,
  deletedStatusUser,
}) {
  const scrollAreaHeight = useRef(180);
  const scrollAreaRef = useRef(null);
  const contentRef = useRef(null);
  const hasLivecam = isLivecamCreator(user) && browser.isAllowed;
  const [messengerScroll, setMessengerScroll] = useState(
    !isMobile && isLivecamCreator(user) && browser.isAllowed
  );
  const messageFilters = [...messageFiltersWithoutGame, ...gameFilter];
  const intl = useIntl();
  const classes = classNames('userlist', {
    'with-voyeur': hasVoyeur,
    expanded: isUserlistOpen,
  });

  useEffect(() => {
    setMessengerScroll(!isMobile && hasLivecam);

    if (!messengerScroll) {
      contentRef.current.style.height = `calc(100% - 60px)`;
      scrollAreaRef.current.style.height = '';
      return;
    }
    const userBoxHeight = isMobile ? 30 : isMedium ? 36 : 40;
    const fixedHeaderHeight = isMobile
      ? 145
      : isMedium || isMediumLarge
      ? 160
      : 180;
    scrollAreaHeight.current =
      fixedHeaderHeight + camUserFiltered.length * userBoxHeight;
    contentRef.current.style.height = '100%';
    scrollAreaRef.current.style.height = `calc(100% - ${scrollAreaHeight.current}px)`;
  }, [
    camUserFiltered.length,
    isMobile,
    isMedium,
    isMediumLarge,
    messengerScroll,
    hasLivecam,
  ]);

  useEffect(() => {
    if (askKickUserConfirmed && askKickUserConfirmedAlert) {
      // Confirmation for kick
      onUserKickConfirm(askKickUserConfirmedAlert);
      toggleUserMenu();
    }
  }, [
    askKickUserConfirmed,
    askKickUserConfirmedAlert,
    onUserKickConfirm,
    toggleUserMenu,
  ]);

  useEffect(() => {
    if (askSendFriendRequestConfirmed && askSendFriendRequestConfirmedAlert) {
      // Confirmation for sending a friend request
      axiosInstance
        .put(`/user/friends/${askSendFriendRequestId}`)
        .then((response) => {
          friendRequestSent();
        })
        .catch((error) => {
          if (error.response?.status === 409) {
            friendsError();
            friendRequestSent();
          }
          if (error.response.status === 404) {
            deletedStatusUser(askSendFriendRequestId);
          }
        });
    }
  }, [
    askSendFriendRequestConfirmed,
    askSendFriendRequestConfirmedAlert,
    askSendFriendRequestId,
    friendRequestSent,
    friendsError,
    deletedStatusUser,
  ]);

  useEffect(() => {
    if (
      askAnswerFriendRequestConfirmed &&
      askAnswerFriendRequestConfirmedAlert
    ) {
      // Confirmation for accepting/denying a friend request
      if (askAnswerFriendRequestType === 'denied') {
        axiosInstance
          .delete(`/user/friends/${askAnswerFriendRequestId}`)
          .then((response) => {
            answerFriendRequest(
              true,
              askAnswerFriendRequestId,
              askAnswerFriendRequestNickname,
              askAnswerFriendRequestType
            );
          })
          .catch((error) => {
            answerFriendRequest(
              true,
              askAnswerFriendRequestId,
              askAnswerFriendRequestNickname
            );
            if (error.response.status === 409) {
              friendsError();
            }
            if (error.response.status === 404) {
              deletedStatusUser(askAnswerFriendRequestId);
            }
            console.log(error);
          });
      } else {
        axiosInstance
          .put(`/user/friends/${askAnswerFriendRequestId}`)
          .then((response) => {
            answerFriendRequest(
              true,
              askAnswerFriendRequestId,
              askAnswerFriendRequestNickname,
              askAnswerFriendRequestType
            );
            console.log(response);
          })
          .catch((error) => {
            answerFriendRequest(
              true,
              askAnswerFriendRequestId,
              askAnswerFriendRequestNickname
            );
            if (error.response.status === 409) {
              friendsError();
            }
            if (error.response.status === 404) {
              deletedStatusUser(askAnswerFriendRequestId);
            }
            console.log(error);
          });
      }
    }
  }, [
    answerFriendRequest,
    askAnswerFriendRequestConfirmed,
    askAnswerFriendRequestConfirmedAlert,
    askAnswerFriendRequestId,
    askAnswerFriendRequestNickname,
    askAnswerFriendRequestType,
    friendsError,
    deletedStatusUser,
  ]);

  useEffect(() => {
    if (askIgnoreUserConfirmed && askIgnoreUserConfirmedAlert) {
      // Confirmation for ignore
      onUserIgnoreConfirm(
        askIgnoreUserConfirmedAlert,
        askIgnoreUserKick,
        askIgnoreUserId
      );
      axiosInstance
        .put(`/user/ignored/${askIgnoreUserId}`)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    askIgnoreUserConfirmed,
    askIgnoreUserConfirmedAlert,
    askIgnoreUserId,
    askIgnoreUserKick,
    onUserIgnoreConfirm,
    toggleUserMenu,
  ]);

  useEffect(() => {
    if (askDeleteFriendConfirmed && askDeleteFriendConfirmedAlert) {
      // Confirmation for deleting a friend
      axiosInstance
        .delete(`/user/friends/${askDeleteFriendId}`)
        .then((response) => {
          deleteFriend(askDeleteFriendId, true);
          console.log(response);
        })
        .catch((error) => {
          deleteFriend(askDeleteFriendId, true);
          if (error.response?.status === 409) {
            friendsError();
          }
          console.log(error);
        });
    }
  }, [
    askDeleteFriendConfirmed,
    askDeleteFriendConfirmedAlert,
    askDeleteFriendId,
    deleteFriend,
    friendsError,
  ]);

  useEffect(() => {
    if (
      askRemoveDeniedFriendRequestConfirmed &&
      askRemoveDeniedFriendRequestConfirmedAlert
    ) {
      // Confirmation for deleting a denied friend request
      axiosInstance
        .delete(`/user/friends/${askRemoveDeniedFriendRequestId}`)
        .then((response) => {
          removeDeniedFriendRequest(
            askRemoveDeniedFriendRequestId,
            askRemoveDeniedFriendRequestNickname
          );
          console.log(response);
        })
        .catch((error) => {
          if (error.response?.status === 409) {
            friendsError();
          }
          if (error.response.status === 404) {
            deletedStatusUser(askRemoveDeniedFriendRequestId);
          }
          console.log(error);
        });
    }
  }, [
    askRemoveDeniedFriendRequestConfirmed,
    askRemoveDeniedFriendRequestConfirmedAlert,
    askRemoveDeniedFriendRequestId,
    askRemoveDeniedFriendRequestNickname,
    friendsError,
    removeDeniedFriendRequest,
    deletedStatusUser,
  ]);

  if (isEvent) return null;
  let messagesTitle = intl.formatMessage({ id: 'title.messenger' });
  if (messagesFilter.startsWith('activities_')) {
    const activity = activitiesFilter.find((af) => af.id === messagesFilter);
    if (activity?.title) {
      messagesTitle = intl.formatMessage({ id: activity.title });
    }
  } else if (messagesFilter !== 'all') {
    const filter = messageFilters.find((mf) => mf.id === messagesFilter);
    messagesTitle = intl.formatMessage({ id: filter.title });
  }

  const onClick = (active) =>
    !active ? onUserClick : isMobile ? toggleUserlistView : () => {};

  const messengerContentClasses = classNames({
    content: messengerScroll,
    'no-scroll-content': !messengerScroll,
  });
  const contentClasses = classNames({
    content: !messengerScroll,
    'no-scroll-content': messengerScroll,
  });
  return (
    <div className={classes}>
      <>
        {' '}
        <div className="top-bar searchbar">
          <SearchInput
            value={camUserFilter}
            name="nickname-search"
            placeholder={intl.formatMessage({ id: 'search.for.nickname' })}
            onChange={(e) => filterUserlist(e.target.value)}
            clearInput={() => filterUserlist('')}
          />
          <SettingsButton
            active={userListMenuOpen}
            onClick={toggleUserlistMenu}
          />
        </div>
        <div className={contentClasses} ref={contentRef}>
          <Scrollbar visible={!messengerScroll}>
            {camUserFiltered.length === 0 &&
            dialogUserFiltered.length === 0 &&
            camUserFilter !== '' ? (
              <div className="no-result">
                {intl.formatMessage({ id: 'no.result' }, { camUserFilter })}
              </div>
            ) : null}
            <UserListMenu />
            {hasLivecam && (
              <Voyeur
                voyeurCount={voyeurCount}
                livecamCount={livecamCount}
                hasVoyeur={hasVoyeur}
                isExclusive={isExclusive}
                headline={intl.formatMessage({ id: 'title.livecam' })}
                text={intl.formatMessage({ id: 'title.voyeur' })}
              />
            )}
            {camUserFiltered.map((userData) => {
              let hasGame;
              let hasInvitation;
              let gameTurn;
              if (gamesEnabled) {
                hasGame = userData?.flags?.includes('game');
                hasInvitation = userData?.flags?.includes('gameInvitation');
                gameTurn = userData?.flags?.includes('gameTurn');
              }
              return (
                <User
                  key={userData.id}
                  {...userData}
                  usercolor={usercolor}
                  onClick={onClick(userData.active)}
                  hasMic={hasMic}
                  hasGame={hasGame}
                  hasInvitation={hasInvitation}
                  gameTurn={gameTurn}
                />
              );
            })}
            <div className="messenger-header">
              <span className="messenger-title">{messagesTitle}</span>
            </div>
            <div className={messengerContentClasses} ref={scrollAreaRef}>
              <Scrollbar visible={messengerScroll}>
                {dialogUserFiltered.map((userData) => {
                  let hasGame;
                  let hasInvitation;
                  let gameTurn;
                  if (gamesEnabled) {
                    hasGame = userData?.flags?.includes('game');
                    hasInvitation = userData?.flags?.includes('gameInvitation');
                    gameTurn = userData?.flags?.includes('gameTurn');
                  }
                  return (
                    <User
                      key={userData.id}
                      {...userData}
                      usercolor={usercolor}
                      color={''}
                      onClick={onClick(userData.active)}
                      hasMic={hasMic}
                      hasGame={hasGame}
                      hasInvitation={hasInvitation}
                      gameTurn={gameTurn}
                      dialogUserIsAdmin={userData?.flags?.includes(
                        'is_support'
                      )}
                      isOnline={userData?.flags?.includes('online')}
                    />
                  );
                })}
                {loading && (
                  <div className="userbox">
                    <Spinner
                      size={20}
                      thickness={2}
                      margin="10px auto"
                      delay={SPINNER_DEFAULT_DELAY}
                    />
                  </div>
                )}
              </Scrollbar>
            </div>
          </Scrollbar>
        </div>
      </>
    </div>
  );
}

export default UserList;
